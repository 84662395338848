import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';

const UserProcedure = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const [userProcedures, setUserProcedures] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFocused, setIsFocused] = useState('');
  const [errors, setErrors] = useState({});
  const [modalButtonGroup, setModalButtonGroup] = useState('createProcedure');

  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the user id');
      return;
    }

    isMountedRef.current = true;

    fetchUserProcedures();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);

  const handleProcedureCreate = () => {
    setModalVisible(true);
    setModalButtonGroup('createProcedure');
    setFormData({
      id: "",
      title: "",
      procedureCount: "",
    });
  }

  const handleProcedureEdit = (data) => {
    setModalButtonGroup('editProcedure');
    setModalVisible(true);
    setFormData({
      id: data?.id || '',
      title: data?.title || '',
      procedureCount: data?.procedure_count || '',
    });
  }

  const handleClose = () => {
    setModalVisible(false);
    setFormData({
      id: "",
      title: "",
      procedureCount: "",
    });
    setErrors({});
    setIsFocused('');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [formData, setFormData] = useState({
    id: "",
    title: "",
    procedureCount: "",
  });

  const imageBaseUrl = `${network.serverip}/images/`;

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const fetchUserProcedures = async () => {

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    try {
      const response = await axios.get(`${network.serverip}/api/user-procedure-list-web/${userId}`);

      if (response.data.success && isMountedRef.current) {

        const userProcedureData = response.data.data || [];

        setUserProcedures(userProcedureData);
        console.log('This is the fetched user procedures:', userProcedureData);

      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUserProcedures([]);
        }
        console.error('Failed to fetch user procedures');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUserProcedures([]);
      }
      console.error('Error fetching user procedures:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setModalVisible(false);
        setFormData({
          id: "",
          title: "",
          procedureCount: "",
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSaveAndAddAnother = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setFormData({
          id: "",
          title: "",
          procedureCount: "",
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {

    console.log('This is the formData', formData);

    setErrors({});

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    let isValid = true;

    if (!formData.title) {
      setErrors(prevErrors => ({
        ...prevErrors,
        title: 'Please add procedure'
      }));
      isValid = false;
    }

    if (!formData.procedureCount) {
      setErrors(prevErrors => ({
        ...prevErrors,
        procedureCount: 'Please enter number of times performed'
      }));
      isValid = false;
    }

    if (!Number.isInteger(formData.procedureCount) || formData.procedureCount <= 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        procedureCount: 'Please enter a positive integer value'
      }));
      isValid = false;
    }

    if (!isValid) {
      return { success: false };
    }

    try {

      formData.userId = userId;

      const response = await axios.post(
        `${network.serverip}/api/user-procedure-${formData.id ? 'edit' : 'create'}-web`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success && isMountedRef.current) {
        console.log(`User procedure uploaded successfully:`, response.data);
        fetchUserProcedures();
        return { success: true };
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
        return { success: false };
      }

    } catch (error) {
      console.error('Error uploading user procedure:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

        return { success: false };

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));

        return { success: false };

      }
    }
  }

  const handleProcedureDelete = async (data) => {

    console.log('handle procedure delete clicked');

    try {

      const response = await axios.delete(`${network.serverip}/api/user-procedure-delete-web/${data.id}`);

      if (response.data.success && isMountedRef.current) {
        console.log('User procedure deleted successfully:', response.data);
        fetchUserProcedures();
        setFormData({
          id: "",
          title: "",
          procedureCount: "",
        });
        setModalVisible(false);
        setErrors({});
      } else {
        console.error('Failed to delete user procedure:', response.data);
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }
    } catch (error) {
      console.error('Failed to delete user procedure:');
      setErrors(prevErrors => ({
        ...prevErrors,
        uploadError: 'Something went wrong, please try again',
      }));
    }

  }


  const styles = UserProcedureStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">

              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => { }}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Procedure
                      </h4>
                    </button>

                    <div>
                      {userProcedures && userProcedures.length > 0 ? (
                        userProcedures.map((proc, index) => {
                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i className="fas fa-hospital-alt font-size-4 text-success"></i>
                                </div>
                                <div className="d-flex justify-content-between w-100">
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {proc?.title}
                                    </p>
                                    <p className="font-size-4 text-default-color line-height-2">
                                      {`Number of times performed: ${proc?.procedure_count}`}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-start">
                                    <button
                                      style={styles.editButton}
                                      onClick={() => { handleProcedureEdit(proc) }}
                                    >
                                      <i className="fas fa-pencil-alt text-success font-size-4"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No procedure available.</p>
                      )}
                    </div>
                    <div className="w-100 pt-9 mb-9 border-top pr-11">
                      <button
                        className="w-100 d-flex align-items-center justify-content-between"
                        onClick={() => { handleProcedureCreate() }}
                        style={styles.addButton}
                      >
                        <p className="font-size-4 text-dodger font-weight-semibold mb-0">
                          {userProcedures.length > 0 ? 'Add another procedure' : 'Add procedure'}
                        </p>
                        <i className="fas fa-plus text-success font-size-4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        size="lg"
        centered
        show={modalVisible}
      >
        <Modal.Body className="p-0">
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
          <form onSubmit={handleSave} className="bg-white rounded-8 overflow-hidden">
            <div className="p-10 w-100">
              <div className="d-flex justify-content-center border-bottom border-mercury pb-2">
                <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                  {modalButtonGroup === 'createProcedure'
                    ? 'Add Procedure'
                    : 'Edit Procedure'}
                </h4>
              </div>
              <div className="w-100 border-bottom border-mercury mb-5">

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="title"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Procedure:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter procedure"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('title')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'title' ? styles.focussedInput : {}}
                  />
                  {errors?.title ? <p className="font-size-3 text-danger">
                    {errors?.title}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="procedureCount"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Number of times performed:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Number of times performed"
                    id="procedureCount"
                    name="procedureCount"
                    value={formData.procedureCount}
                    onChange={(e) => {
                      let count = parseInt(e.target.value);
                      setFormData({
                        ...formData,
                        [e.target.name]: count
                      });
                    }}
                    onFocus={() => setIsFocused('procedureCount')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'procedureCount' ? styles.focussedInput : {}}
                  />
                  {errors?.procedureCount ? <p className="font-size-3 text-danger">
                    {errors?.procedureCount}
                  </p> : null}
                </div>

              </div>

              <div className="w-100 d-flex justify-content-end">
                {modalButtonGroup === 'createProcedure' ? (
                  <div className="w-100 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveAndAddAnother}
                      type="button"
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Save and add another
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="w-100 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => { handleProcedureDelete(formData) }}
                      className="btn btn-outline-danger h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Delete
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                )}
              </div>

              <div className="w-100 d-flex justify-content-center">
                {errors?.uploadError ? <p className="font-size-3 text-danger">
                  {errors?.uploadError}
                </p> : null}
              </div>

            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UserProcedure;

function UserProcedureStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    addButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    editButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    suggestionContainer: {
      maxHeight: "200px",
      overflowY: "auto",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    suggestionItemStyle: {
      cursor: "pointer",
      backgroundColor: "white",
      border: "none",
      outline: 'none',
    },
    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  };

  return styles;
}

